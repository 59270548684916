import React from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Edit,
  DisabledInput,
  TextInput,
  Create,
  Datagrid,
  SimpleForm,
  BooleanField,
  BooleanInput,
  NumberField,
  NumberInput,
  ImageField,
  ImageInput,
  SimpleList,
  Responsive,
  required,
  email,
  number,
  Toolbar,
  SaveButton,
  SelectArrayInput,
  ReferenceArrayInput,
  SelectInput,
} from "react-admin";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import RichTextInput from "ra-input-rich-text";

import CustomImageField from "./CustomLogoImageField";

const boxProps = {
  bgcolor: "background.paper",
  borderColor: "text.primary",
  m: 1,
  border: 1,
};

export const ListTransferProviders = (props) => (
  <List
    title="Providers"
    {...props}
    bulkActions={false}
    pagination={<PostPagination />}
  >
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <CustomImageField source="logo" />
          <TextField source="name" label="Name" />
          <TextField source="cui" label="CUI" />
          <TextField source="regNr" label="Registration number " />
          <TextField source="mail" label="Email" />
          <TextField source="address" label="Address" />
          <NumberField
            source="dislocationCost"
            label="Dislocation cost"
            options={{ maximumFractionDigits: 2 }}
          />
        </Datagrid>
      }
    />
  </List>
);

const ProviderName = ({ record }) => {
  if (record.cardTypes)
    record.cardTypes = record.cardTypes.map(function (cardType) {
      return cardType.id ? cardType.id : cardType;
    });
  return <span>Admin {record ? `${record.name}` : ""}</span>;
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const TransferProviderEdit = (props) => (
  <Edit title={<ProviderName />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list">
      <TextInput validate={required()} source="name" label="Name" />
      <TextInput validate={required()} source="cui" label="CUI" />
      <TextInput source="regNr" label="Registration number" />
      <TextField source="mail" label="Email" />
      <TextInput source="address" label="Address" />
      <ReferenceArrayInput
        label="Supported card types"
        source="cardTypes"
        reference="cardTypes"
      >
        <SelectArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <h3>Politici</h3>

            <NumberInput
              validate={[number()]}
              source="dislocationCost"
              label="Dislocation Cost"
              helperText="Free in case of value 0."
            />
          </Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          ></Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <h3>Pick-up/Drop-off Details</h3>

            <TextInput source="openingTime" placeholder="9:00" label="Open" />
            <TextInput source="closingTime" placeholder="17:00" label="Close" />
          </Grid>
        </Container>
      </Box>

      <ImageInput
        source="logoProvider"
        label="Logo"
        multiple={false}
        accept="image/*"
      >
        <ImageField source="sourceValue" title="filename" />
      </ImageInput>
      <RichTextInput fullWidth={true} source="policy" label="Policy" />
      <RichTextInput
        fullWidth={true}
        source="policyEn"
        label="Policy(English)"
      />
    </SimpleForm>
  </Edit>
);

export const TransferProviderCreate = (props) => (
  <Create title="Add provider" {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} source="name" label="Name" />
      <TextInput validate={required()} source="cui" label="CUI" />
      <TextInput source="regNr" label="Registration number" />
      <TextInput source="mail" label="Email" />
      <TextInput source="address" label="Address" />
      <ReferenceArrayInput
        label="Supported card types"
        source="cardTypes"
        reference="cardTypes"
      >
        <SelectArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <h3>Politici</h3>

            <NumberInput
              validate={[number()]}
              source="dislocationCost"
              label="Dislocation Cost"
              helperText="Free in case of value 0."
            />
          </Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          ></Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <h3>Pick-up/Drop-off Details</h3>

            <TextInput source="openingTime" placeholder="9:00" label="Open" />
            <TextInput source="closingTime" placeholder="17:00" label="Close" />
          </Grid>
        </Container>
      </Box>

      <ImageInput
        source="logoProvider"
        label="Logo"
        multiple={false}
        accept="image/*"
      >
        <ImageField source="sourceValue" title="filename" />
      </ImageInput>
      <RichTextInput fullWidth={true} source="policy" label="Policy" />
      <RichTextInput
        fullWidth={true}
        source="policyEn"
        label="Policy(English)"
      />
    </SimpleForm>
  </Create>
);
