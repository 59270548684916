import React from "react";

class CustomImageField extends React.Component {
  logoProvider;

  constructor(props) {
    super(props);
    this.logoProvider = this.props.record["logoProvider"];
  }

  render() {
    if (this.logoProvider) {
      return (
        <img style={{"max-height": "60px", "max-width": "90px"}} src={ this.logoProvider.sourceValue }
        />
      );
    }

    return "";
  }
}

export default CustomImageField;
