import React from "react";
import { Admin, Resource, Login, resolveBrowserLocale } from "react-admin";
import Dashboard from "./Dashboard";
import { ListLocation, LocationEdit, LocationCreate } from "./locations.js";
import { ListLocationTransfers, LocationEditTransfers, LocationCreateTransfers} from "./location-transfers.js"
import { ListCars, CarsEdit, CarsCreate } from "./cars.js";
import { ListProviders, ProviderEdit, ProviderCreate } from "./providers.js";
import { BrandCreate, ListBrands, BrandEdit } from "./brands.js";
import { ListDiscounts, DiscountEdit, DiscountCreate } from "./discounts.js";
import { ListIndisponibility, IndisponibilityEdit, IndisponibilityCreate } from "./indisponibility.js";
import { ListSeasons, SeasonEdit, SeasonCreate } from "./seasons.js";
import {ListTransferReservations,ShowTransferReservations,TransferRezervationEdit,} from "./transferReservation.js";
import {ListTransferCars,TransferCarsEdit,TransferCarsCreate,} from "./transfer-cars.js";
import {BrandTransferCreate,ListBrandsTransfer,BrandTransferEdit,} from "./brands-transfer.js";
import {ListTransferProviders,TransferProviderEdit,TransferProviderCreate,} from "./transfer-providers.js";
import NotFound from "./notFound";
import { createMuiTheme } from "@material-ui/core/styles";
import yellow from "@material-ui/core/colors/yellow";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider.js";
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  ListRentalReservations, ShowRentalReservations, RezervationEdit, RentalReservationCreate
} from "./rentalReservations.js";
import englishMessages from 'ra-language-english';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: yellow,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});

const MyLoginPage = () => (
  <Login
      // A random image that changes everyday
      backgroundImage=""
  />
);

const roMessages = {
  ra: {
      notification: {
          http_error: 'Eroare de conexiune. Va rugam sa mai incercati.',
      },
      action: {
          save: 'Salveza',
          delete: 'Sterge',
          show: 'Vizualizeaza',
          create: 'Adauga',
          cancel: 'Anuleaza',
          edit: 'Administreaza',
          list: 'Vizualizeaza',
          export: 'Exporta',
          search: 'Cauta',
          refresh: 'Actualizeaza'
      },
      boolean: {
          true: 'Da',
          false: 'Nu',
          null: '',
      },
      page: {
          create: 'Adauga %{name}',
          dashboard: 'Pagina principala',
          error: 'Ceva nu a mers bine. Va rugam sa mai incercati',
          loading: 'Se incarca',
          not_found: 'Nu a fost gasit'
      },
      auth: {
          auth_check_error: 'Va rugam sa va autentificati pentru a continua',
          user_menu: 'Profil',
          username: 'Nume utilizator',
          password: 'Parola',
          sign_in: 'Autentificare',
          sign_in_error: 'Autentificare esuata! Va rugam sa mai incercati.',
          logout: 'Deconectare',
      },
      validation: {
          required: 'Necesar',
          minLength: 'Lungimea trebuie sa fie de cel putin %{min} caractere',
          maxLength: 'Lungimea nu poate fi mai mare de %{max} caractere',
          minValue: 'Trebuie sa fie cel putin %{min}',
          maxValue: 'Trebuie sa fie cel mult %{max} sau mai putin',
          number: 'Trebuie sa fie un numar',
          email: 'Trebuie sa fie un email valid',
          oneOf: 'Trebuie sa fie una dintre: %{options}',
          regex: 'Trebuie sa se potriveasca cu formatul: %{pattern}',
      },
  },
};

const messages = {
  ro: roMessages,
  en: englishMessages,
};

const i18nProvider = locale => messages[locale];

const App = () => (
  <Admin locale="ro" i18nProvider={i18nProvider}
    loginPage={MyLoginPage}
    theme={theme}
    catchAll={NotFound}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    title="Panou administrare"
    authProvider={authProvider}
  >
    { role => [
      <Resource
        name="rentalReservation"
        list={ListRentalReservations}
        show={ShowRentalReservations}
        edit={RezervationEdit}
        create={role === "ROLE_ADMIN" ? RentalReservationCreate : null}
        options={{ label: "Rezervari" }}
        icon={ScheduleIcon}
      />,
      <Resource
        name="transferReservation"
        list={ListTransferReservations}
        show={ShowTransferReservations}
        edit={TransferRezervationEdit}
        options={{ label: "Transfer Bookings" }}
        icon={ScheduleIcon}
      />,
      <Resource name="carsForAlternatives" />,
      <Resource name="months" />,
      <Resource name="years" />,
      <Resource name="rentalStatuses" />,
      <Resource name="transmissions" />,
      <Resource name="cardTypes" />,
      <Resource name="insurances" />,
      <Resource name="carClasses" />,
      <Resource name="seasonType" />,
      <Resource name="fuel" />,
      <Resource name="options" />,
      <Resource
        name="cars"
        list={ListCars}
        edit={role === "ROLE_ADMIN" ? CarsEdit : null}
        create={role === "ROLE_ADMIN" ? CarsCreate : null}
        options={{ label: "Masini" }}
        icon={DirectionsCarIcon}
      />,
      <Resource
        name="transfers-cars"
        list={ListTransferCars}
        edit={role === "ROLE_ADMIN" ? TransferCarsEdit : null}
        create={role === "ROLE_ADMIN" ? TransferCarsCreate : null}
        options={{ label: "Transferuri" }}
        icon={CompareArrowsIcon}
      />,
      role === "ROLE_ADMIN" ? (
        <Resource
          name="brands"
          options={{ label: "Sabloane masini" }}
          list={ListBrands}
          edit={BrandEdit}
          create={BrandCreate}
          icon={DescriptionIcon}
        />
      ) : null,
      role === "ROLE_ADMIN" ? (
        <Resource
          name="brands-transfer"
          options={{ label: "Sabloane transferuri" }}
          list={ListBrandsTransfer}
          edit={BrandTransferEdit}
          create={BrandTransferCreate}
          icon={DescriptionIcon}
        />
      ) : null,
      role === "ROLE_PROVIDER" ? (
        <Resource
          name="brands" />
      ) : null,
      role === "ROLE_ADMIN" ? (
        <Resource
          name="providers"
          list={ListProviders}
          edit={ProviderEdit}
          create={ProviderCreate}
          options={{ label: "Furnizori" }}
          icon={EmojiTransportationIcon}
        />
      ) : null,
      role === "ROLE_ADMIN" ? (
        <Resource
          name="transfer-providers"
          list={ListTransferProviders}
          edit={TransferProviderEdit}
          create={TransferProviderCreate}
          options={{ label: "Furnizori transferuri" }}
          icon={EmojiTransportationIcon}
        />
      ) : null,
      // role === "ROLE_ADMIN" ? (
      //   <Resource
      //     name="transmissions"
      //     list={ListTransmissions}
      //     edit={TransmissionEdit}
      //     create={TransmissionCreate}
      //   />
      // ) : null,
      // role === "ROLE_ADMIN" ? (
      //   <Resource
      //     name="invoices"
      //     list={ListInvoices}
      //     edit={InvoiceEdit}
      //     create={InvoiceCreate}
      //   />
      // ) : null,
    
      // Restrict access to the edit and remove views to admin only
      <Resource
        name="locations"
        options={{ label: "Locatii" }}
        list={role === "ROLE_ADMIN" ? ListLocation : null}
        edit={role === "ROLE_ADMIN" ? LocationEdit : null}
        create={role === "ROLE_ADMIN" ? LocationCreate : null}
        icon={LocationOnIcon}
      />,
      <Resource
        name="location-transfers"
        options={{ label: "Locatii Transferuri" }}
        list={role === "ROLE_ADMIN" ? ListLocationTransfers : null}
        edit={role === "ROLE_ADMIN" ? LocationEditTransfers : null}
        create={role === "ROLE_ADMIN" ? LocationCreateTransfers : null}
        icon={LocationOnIcon}
      />,
      // role === "ROLE_ADMIN" ? (
      //   <Resource
      //     name="options"
      //     list={ListOptions}
      //     edit={OptionEdit}
      //     create={OptionCreate}
      //   />
      // ) : null,
      <Resource
        name="discounts"
        list={role === "ROLE_ADMIN" ?ListDiscounts : null}
        edit={role === "ROLE_ADMIN" ?DiscountEdit : null}
        create={role === "ROLE_ADMIN" ?DiscountCreate : null}
        options={{ label: "Reduceri" }}
        icon={MoneyOffIcon}
      />,
      <Resource
      name="seasons"
      list={ListSeasons}
      edit={SeasonEdit}
      create={SeasonCreate}
      options={{ label: "Season" }}
      icon={MoneyOffIcon}
    />,
    <Resource
    name="indisponibility"
    list={ListIndisponibility}
    edit={IndisponibilityEdit}
    create={IndisponibilityCreate}
    options={{ label: "Indisponibility" }}
    icon={HighlightOffIcon}
  />,
    ]}
  </Admin>
);

export default App;