import React, { useState, useEffect } from "react";
import PostPagination from "./pagination.js";
import DeleteButton from "./DeleteButton.js";
import companyLogo from "./desc.jpeg";

import {
  List,
  TextField,
  Edit,
  Create,
  Datagrid,
  SimpleForm,
  NumberField,
  NumberInput,
  SelectInput,
  Responsive,
  SimpleList,
  Filter,
  SelectArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  required,
  number,
} from "react-admin";

export const ListTransferCars = (props) => {

  console.log(localStorage);
  const providerId = localStorage.getItem("providerId");
  const role = localStorage.getItem("role");
  const [url, setUrl] = useState(null);
  const mystyle = {
    margin: "15px",
    textDecoration: "none",
    color: "#2196f3",
  };
  const imageStyle = {
    height: "20px",
    width: "20px",
    margin:"0 5px 0 0"
  };


  useEffect(() => {
  if(role==="ROLE_ADMIN"){
    setUrl("https://api.topcarrentals.ro/exportTransfersByProvider?provider="+providerId);
    console.log(role,url);
  }else if(role==="ROLE_PROVIDER"){
    setUrl("https://api.topcarrentals.ro/exportTransfersByProvider?provider=" + providerId);
  }
}, []);

  return (
    <>
    <a
      style={mystyle}
      href={url}
    >
      <img style={imageStyle} src={companyLogo} />
      <span>Export</span>
    </a>
      <List
      filters = {<FilterProvider />}
        title=" Transfers Cars"
        {...props}
        bulkActions={false}
        pagination={<PostPagination />}
      >
        <Responsive
          medium={
            <Datagrid rowClick="edit">
              <TextField source="brand.brand" sortable={false} label="Brand" />
              <TextField source="brand.model" sortable={false} label="Model" />
              <TextField source="provider.name" sortable={false} label="Provider" />
              <TextField
                source="location.name"
                sortable={false}
                label="Pick-up Location"
              />
              <TextField
                source="dropOffLocation.name"
                sortable={false}
                label="Drop-off Location"
              />

              <NumberField
                source="pricePerTransfer"
                label="Price per transfer"
                options={{ maximumFractionDigits: 2 }}
              />

              <NumberField
                source="pricePerTransferReturn"
                label="Price per return transfer"
                options={{ maximumFractionDigits: 2 }}
              />
            </Datagrid>
          }
        />
      </List>
    </>
  )
};

const CarName = ({ record }) => {
  // Prepare data in title compute
  record.brandId = record.brand.id;
  record.locationId = record.location.id;
  record.dropOffLocationIds = record.dropOffLocation.id;
  record.providerId = record.provider.id;
  return (
    <span>
      Car{" "}
      {record
        ? `${record.brand.brand} ${record.brand.model} for ${record.provider.name}`
        : ""}
    </span>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    &nbsp;&nbsp;
    <DeleteButton
      label="Remove car"
      doAction={() => deleteCar(props)}
      title="Remove car"
      content="Are you sure you want to delete this car?"
    />
  </Toolbar>
);

const deleteCar = ({ record = {} }) => {
  const request = new Request(
    "https://api.topcarrentals.ro/transfers-cars/" + record.id + "/delete",
    {
      method: "GET",
      headers: {
        "X-Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  fetch(request).then((response) => {
    document.location.href = "#/transfers-cars/";
  });
};

const FilterProvider = (props) => (
  <Filter {...props}>
    {localStorage.getItem("role") === "ROLE_ADMIN" && (
      <ReferenceInput
      label="Provider"
      source="providerId"
      reference="transfer-providers"
        allowEmpty
        alwaysOn
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
    )}
  </Filter>
);

export const TransferCarsEdit = (props) => (
  <Edit title={<CarName />} {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <ReferenceInput
        validate={required()}
        label="Car Template"
        source="brandId"
        reference="brands-transfer"
      >
        <SelectInput optionValue="id" optionText="brandWithModel" />
      </ReferenceInput>

      <ReferenceInput
        validate={required()}
        label="Pick-up Location"
        source="locationId"
        reference="location-transfers"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        validate={required()}
        label="Drop-off Location"
        source="dropOffLocationIds"
        reference="location-transfers"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        validate={required()}
        label="Provider"
        source="providerId"
        reference="transfer-providers"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>

      <h3>Price</h3>
      <table>
        <tr>
          <td>
            <NumberInput
              validate={[required(), number()]}
              source="pricePerTransferReturn"
              label="Price per return transfer"
            />
          </td>
          <td>
            <NumberInput
              validate={[required(), number()]}
              source="pricePerTransferReturn"
              label="Price per return transfer"
            />
          </td>
        </tr>
      </table>
    </SimpleForm>
  </Edit>
);

export const TransferCarsCreate = (props) => {
  const [file, setFile] = useState(null);

  return (
    <>

      <form action="https://api.topcarrentals.ro/importTransfers" method="post" enctype="multipart/form-data">
        <input id="fileSelect" name="importFile" type="file" onChange={(e) => setFile(e.target.files[0])} accept=".csv" />
        <button type="submit">Submit</button>
      </form>

      <Create title="Add Car" {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
          <ReferenceInput
            validate={required()}
            label="Transfer car template"
            source="brandId"
            reference="brands-transfer"
          >
            <SelectInput optionValue="id" optionText="brand" />
          </ReferenceInput>

          <ReferenceArrayInput
            validate={required()}
            label="Location"
            source="locationIds"
            reference="location-transfers"
          >
            <SelectArrayInput
              validate={required()}
              optionValue="id"
              optionText="name"
            />
          </ReferenceArrayInput>

          <ReferenceArrayInput
            validate={required()}
            label="Drop-off Location"
            source="dropOfflocationIds"
            reference="location-transfers"
          >
            <SelectArrayInput
              validate={required()}
              optionValue="id"
              optionText="name"
            />
          </ReferenceArrayInput>

          <ReferenceInput
            validate={required()}
            label="Provider"
            source="providerId"
            reference="transfer-providers"
          >
            <SelectInput optionValue="id" optionText="name" />
          </ReferenceInput>

          <h3>Prices</h3>
          <table>
            <tr>
              <td>
                <NumberInput
                  validate={[required(), number()]}
                  source="pricePerTransfer"
                  label="Price per transfer"
                />
              </td>
              <td>
                <NumberInput
                  validate={[required(), number()]}
                  source="pricePerTransferReturn"
                  label="Price per return transfer"
                />
              </td>
            </tr>
          </table>
        </SimpleForm>
      </Create>
    </>
  )
};
