import React from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  NumberField,
  Responsive,
  SimpleList,
  BooleanInput,
  BooleanField,
  NumberInput,
  SelectInput,
  ImageField,
  ImageInput,
  Toolbar,
  SaveButton,
  ReferenceInput,
  required,
  number,
} from "react-admin";

export const ListBrandsTransfer = (props) => (
  <List
    {...props}
    title="Transfer Car Templates"
    bulkActions={false}
    pagination={<PostPagination />}
  >
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <TextField sortable={false} source="brand" label="Brand" />
          <TextField sortable={false} source="model" label="Model" />
          <BooleanField
            sortable={false}
            source="airConditioning"
            label="Air Conditioning"
          />
          <NumberField
            sortable={false}
            source="seats"
            label="Number of seats"
          />
          <NumberField
            sortable={false}
            source="doors"
            label="Number of doors"
          />
          <NumberField sortable={false} source="luggage" label="Luggages" />
          <TextField sortable={false} source="carClass" label="Car Class" />
        </Datagrid>
      }
    />
  </List>
);

const BrandTransferName = ({ record }) => {
  return (
    <span>Template {record ? `${record.brand} ${record.model}` : ""}</span>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const BrandTransferEdit = (props) => (
  <Edit title={<BrandTransferName />} {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <TextInput validate={required()} source="brand" label="Brand" />
      <TextInput validate={required()} source="model" label="Model" />
      <BooleanInput source="airConditioning" label="Air conditioning" />
      <NumberInput
        validate={[required(), number()]}
        source="seats"
        label="Number of seats"
      />
      <NumberInput
        validate={[required(), number()]}
        source="doors"
        label="Number of doors"
      />
      <NumberInput
        validate={[required(), number()]}
        source="luggage"
        label="Luggages"
      />
      <ReferenceInput
        validate={required()}
        label="Car Classes"
        source="carClassId"
        reference="carClasses"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <ImageInput
        validate={required()}
        multiple
        source="images"
        accept="image/*"
        label="Images"
      >
        <ImageField source="sourceValue" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const BrandTransferCreate = (props) => (
  <Create title="Add template" {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} source="brand" label="Brand" />
      <TextInput validate={required()} source="model" label="Model" />
      <BooleanInput source="airConditioning" defaultValue={true} />
      <NumberInput
        validate={[required(), number()]}
        source="seats"
        label="Number of seats"
      />
      <NumberInput
        validate={[required(), number()]}
        source="doors"
        label="Number of doors"
      />
      <NumberInput
        validate={[required(), number()]}
        source="luggage"
        label="Luggages"
      />
      <ReferenceInput
        validate={required()}
        label="Car Classes"
        source="carClassId"
        reference="carClasses"
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ImageInput
        multiple
        source="images"
        accept="image/*"
        label="Images"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
