import React,{useState,useEffect} from "react";
import PostPagination from "./pagination.js";
import DeleteButton from "./DeleteButton.js";
import companyLogo from "./desc.jpeg";

import {
  List,
  TextField,
  Edit,
  Create,
  Datagrid,
  SimpleForm,
  NumberField,
  NumberInput,
  SelectInput,
  Filter,
  Responsive,
  ArrayField,
  ChipField,
  ReferenceArrayField,
  ReferenceManyField,
  SingleFieldList,
  SimpleList,
  SelectArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  required,
  number
} from "react-admin";

export const ListCars = props => {
  console.log(localStorage);
  const providerId = localStorage.getItem("providerId");
  const role = localStorage.getItem("role");
  const [url, setUrl] = useState(null);
  const mystyle = {
    margin: "15px",
    textDecoration: "none",
    color: "#2196f3",
  };
  const imageStyle = {
    height: "20px",
    width: "20px",
    margin:"0 5px 0 0"
  };
  useEffect(() => {
  if(role==="ROLE_ADMIN"){
    setUrl("https://api.topcarrentals.ro/exportCarByProvider?provider="+providerId);
    console.log(role,url);
  }else if(role==="ROLE_PROVIDER"){
    setUrl("https://api.topcarrentals.ro/exportCarByProvider?provider=" + providerId);
  }
}, []);

  return (
    <>
    <a
      style={mystyle}
      href={url}
    >
      <img style={imageStyle} src={companyLogo} />
      <span>Export</span>
    </a>
  <List 
  filters={<FilterProvider />}
  title="Masini" {...props} bulkActions={false} pagination={<PostPagination />}>
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <TextField source="brand.brand" sortable={false} label="Marca" />
          <TextField source="brand.model" sortable={false} label="Model" />
          <TextField source="provider.name" sortable={false} label="Furnizor" />
          {/* <TextField source="location" sortable={false} label="Locatie"  /> */}
          <LocationNameList  label="Location"/>
          <NumberField
            source="pricePerDay"
            label="Pret pe zi"
            options={{ maximumFractionDigits: 2 }}
          />
        </Datagrid>
      }
    />
  </List>
  </>
  );
};

const LocationNameList = ({ record }) => {
  if (record.location){
    record.locationNames = record.location.map(function(locationId) {
      return locationId.name ? locationId.name : locationId;
    });
  }
  return <span class="locationNames"> {record ? `${record.locationNames}` : ""}</span>;
}

const CarName = ({ record }) => {
  // Prepare data in title compute
  record.brandId = record.brand.id;
  // record.locationId = record.location.id;

  if (record.location){
    record.locationIds = record.location.map(function(locationId) {
      return locationId.id ? locationId.id : locationId;
    });
  }

  record.providerId = record.provider.id;
  return <span>Masina {record ? `${record.brand.brand} ${record.brand.model} for ${record.provider.name}` : ""}</span>;
};

const CustomToolbar = props => (
  <Toolbar {...props}>
      <SaveButton/>
      &nbsp;&nbsp;
      <DeleteButton label="Sterge masina" doAction={() => deleteCar(props)} title="Sterge masina" content="Sunteti sigur ca doriti sa stergeti aceasta masina?" />
  </Toolbar>
);


const deleteCar = ({ record = {} }) => {
    const request = new Request("https://api.topcarrentals.ro/cars/" + record.id + "/delete", {
        method: "GET",
        headers: { "X-Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-type": "application/json; charset=UTF-8" }
      });
      fetch(request)
        .then(response => {
          document.location.href = '#/cars/';
        });
}
const FilterProvider = (props) => (
  <Filter {...props}>
    {localStorage.getItem("role") === "ROLE_ADMIN" && (
      <ReferenceInput
        label="Provider"
        source="provider"
        reference="providers"
        allowEmpty
        alwaysOn
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
    )}
  </Filter>
);
export const CarsEdit = (props) => (  
  <Edit title={<CarName />} {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <ReferenceInput validate={required()} label="Sablon masina" source="brandId" reference="brands">
          <SelectInput optionValue="id" optionText="brandWithModel" />
      </ReferenceInput>

      <ReferenceArrayInput validate={required()} label="Locatie" source="locationIds" reference="locations">
          <SelectArrayInput validate={required()} optionValue="id" optionText="name" />
      </ReferenceArrayInput>

      <ReferenceInput validate={required()} label="Furnizor" source="providerId" reference="providers">
          <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>

      <h3>Prices</h3>
      <table>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDay" label="Pret 1-3 zile"/>
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDaySecond" label="Pret 4-9 zile" />
          </td>
        </tr>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDayThird" label="Pret 10-15 zile" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDayFourth" label="Pret 16-21 zile" />
          </td>
        </tr>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDayFifth" label="Pret 22-30 zile" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDaySixth" label="Pret peste 30 zile" />
          </td>
        </tr>
      </table>
        <NumberInput validate={[required(), number()]} source="specialPrice" label="Pret special" helperText="Se aplica in cazul masinilor de lux ce se inchiriaza pentru maxim 12 ore pe zi." />

      <h3>Deposits &amp; Insurances</h3>
      <table>
        <NumberInput validate={[required(), number()]} source="firstInsuranceDeposit" label="Depozit standard" />
  
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="secondInsuranceDeposit" label="Depozit mediu" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="secondInsurancePerDay" label="Mediu pe zi" helperText="Pretul pe zi pentru asigurarea medie. Nu se aplica in cazul valorii 0." />
          </td>
        </tr>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="thirdInsuranceDeposit" label="Depozit top" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="thirdInsurancePerDay" label="Top pe zi" helperText="Pretul pe zi pentru asigurarea de top. Nu se aplica in cazul valorii 0." />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="warranty" label="Garantie" helperText="Nu se aplica in cazul valorii 0." />
          </td>
        </tr>
      </table>
    </SimpleForm>
  </Edit>
);

export const CarsCreate = props => {
  const [file,setFile]=useState(null);

  return (
    <>
  <form action="https://api.topcarrentals.ro/importCars" method="post" enctype="multipart/form-data">
  <input id="fileSelect"name="importFile" type="file" onChange={(e)=>setFile(e.target.files[0])} accept=".csv" />  
      <button type="submit">Submit</button>
      </form>
    <Create title="Add Car" {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
    <ReferenceInput validate={required()} label="Sablon masina" source="brandId" reference="brands">
          <SelectInput optionValue="id" optionText="brandWithModel" />
      </ReferenceInput>

      <ReferenceArrayInput validate={required()} label="Locatie" source="locationIds" reference="locations">
          <SelectArrayInput validate={required()} optionValue="id" optionText="name" />
      </ReferenceArrayInput>

      <ReferenceInput validate={required()} label="Furnizor" source="providerId" reference="providers">
          <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>

      <h3>Prices</h3>
      <table>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDay" label="Pret 1-3 zile"/>
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDaySecond" label="Pret 4-9 zile" />
          </td>
        </tr>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDayThird" label="Pret 10-15 zile" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDayFourth" label="Pret 16-21 zile" />
          </td>
        </tr>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDayFifth" label="Pret 22-30 zile" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="pricePerDaySixth" label="Price peste 30 zile" />
          </td>
        </tr>
      </table>
      <NumberInput validate={[required(), number()]} source="specialPrice" label="Pret special" helperText="Se aplica in cazul masinilor de lux ce se inchiriaza pentru maxim 12 ore pe zi." />

      <h3>Deposits &amp; Insurances</h3>
      <table>
        <NumberInput validate={[required(), number()]} source="firstInsuranceDeposit" label="Depozit standard" />
  
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="secondInsuranceDeposit" label="Depozit mediu" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="secondInsurancePerDay" label="Mediu pe zi" helperText="Pretul pe zi pentru asigurarea medie. Nu se aplica in cazul valorii 0." />
          </td>
        </tr>
        <tr>
          <td>
            <NumberInput validate={[required(), number()]} source="thirdInsuranceDeposit" label="Depozit top" />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="thirdInsurancePerDay" label="Top pe zi" helperText="Pretul pe zi pentru asigurarea de top. Nu se aplica in cazul valorii 0."  />
          </td>
          <td>
            <NumberInput validate={[required(), number()]} source="warranty" label="Warranty" helperText="Nu se aplica in cazul valorii 0." />
          </td>
        </tr>
      </table>

    </SimpleForm>
  </Create>
  </>
)};
