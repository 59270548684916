import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  fetchUtils
} from "react-admin";

const API_URL = "https://api.topcarrentals.ro";

/**
 * convert file to base64
 */
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const computeDate = (dateString) => {
  const date = new Date(dateString);

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
};

// /**
//  * Query a data provider and return a promise for a response
//  *
//  * @example
//  * dataProvider(GET_ONE, 'locations', { id: 40 })
//  *  => Promise.resolve({ data: { id: 40, name: "Arad" } })
//  *
//  * @param {string} type Request type, e.g GET_LIST
//  * @param {string} resource Resource name, e.g. "posts"
//  * @param {Object} payload Request parameters. Depends on the action type
//  * @returns {Promise} the Promise for a response
//  */

const convertDataProviderRequestToHTTP = async (type, resource, params) => {
  const role = localStorage.getItem("role");
  switch (type) {
    case GET_MANY:
    case GET_LIST:
      if (role === "ROLE_PROVIDER") {
        const providerId = localStorage.getItem("providerId");
        if (resource === "cars") {
          return {
            url: `${API_URL}/api/${resource}/provider/${providerId}`
          };
        } else if (resource === "transfers-cars") {
          return {
            url: `${API_URL}/api/${resource}/provider/${providerId}`
          };
        } else if (resource === "rentalReservation") {
          const query = {
            filter: JSON.stringify(params.filter)
          };
          return {
            url: `${API_URL}/api/${resource}/provider/${providerId}?${stringify(query)}`
          };
        } else if (resource === "transferReservation") {
          const query = {
            filter: JSON.stringify(params.filter)
          };

          return {
            url: `${API_URL}/api/${resource}/provider/${providerId}?${stringify(query)}`
          };
        }
        else if (resource === "cars") {
          const query = {
            filter: JSON.stringify(params.filter),
          };

          return {
            url: `${API_URL}/api/${resource}/provider/${providerId}?${stringify(
              query
            )}`,
          };
        }
        else if (resource === "transfers-cars") {
          const query = {
            filter: JSON.stringify(params.filter),
          };

          return {
            url: `${API_URL}/api/${resource}/provider/${providerId}?${stringify(
              query
            )}`,
          };
        }
        else if (resource === "carsForAlternatives") {
          return {
            url: `${API_URL}/api/${resource}/` + localStorage.getItem("reservationForAlt")
          };
        } else if (resource === "discounts") {
          return {
            url: `${API_URL}/${resource}/provider/${providerId}`
          };
        } else if (resource === "seasons") {
          return {
            url: `${API_URL}/${resource}/provider/${providerId}`
          };
        } else if (resource === "indisponibility") {
          return {
            url: `${API_URL}/${resource}/provider/${providerId}`
          };
        }
      }
      if (resource === "rentalReservation") {
        const query = {
          filter: JSON.stringify(params.filter)
        };

        return {
          url: `${API_URL}/api/${resource}?${stringify(query)}`
        };
      }
      else if (resource === "transferReservation") {
        const query = {
          filter: JSON.stringify(params.filter)
        };

        return {
          url: `${API_URL}/api/${resource}?${stringify(query)}`
        };
      }
      else if (resource === "cars") {
        const query = {
          filter: JSON.stringify(params.filter),
        };
        localStorage.setItem("providerId", params.filter.provider);
        console.log(params.filter.provider)
        return {
          url: `${API_URL}/api/${resource}?${stringify(query)}`,
        };
      }
      else if (resource === "transfers-cars") {
        const query = {
          filter: JSON.stringify(params.filter),
        };
        localStorage.setItem("providerId", params.filter.provider);
        console.log(params.filter.provider)
        return {
          url: `${API_URL}/api/${resource}?${stringify(query)}`,
        };
      }
      return {
        url: `${API_URL}/${resource}`
      };
    case GET_ONE:
      if (resource === "rentalReservation") {
        return {
          url: `${API_URL}/api/${resource}/${params.id}`,
          options: { method: "GET", body: JSON.stringify(params.data) }
        };
      } else if (resource === "transferReservation") {
        return {
          url: `${API_URL}/api/${resource}/${params.id}`,
          options: { method: "GET", body: JSON.stringify(params.data) }
        };
      }
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: "GET", body: JSON.stringify(params.data) }
      };
    case UPDATE:
      if (resource === "brands") {
        if (params.data.images && params.data.images.length) {
          params.data.images = await Promise.all(params.data.images.map(async (img) => {
            if (img.rawFile) {
              var file = await toBase64(img.rawFile);
              return { "id": 0, "filename": img.rawFile.name, "contentType": img.rawFile.type, "sourceValue": file }
            } else {
              return { "id": img.id, "filename": img.filename, "contentType": img.contentType, "data": img.data };
            }
          }));
        }
      } else if (resource === "brands-transfer") {
        if (params.data.images && params.data.images.length) {
          params.data.images = await Promise.all(params.data.images.map(async (img) => {
            if (img.rawFile) {
              var file = await toBase64(img.rawFile);
              return { "id": 0, "filename": img.rawFile.name, "contentType": img.rawFile.type, "sourceValue": file }
            } else {
              return { "id": img.id, "filename": img.filename, "contentType": img.contentType, "data": img.data };
            }
          }));
        }
      } else if (resource === "providers") {
        if (params.data.logoProvider) {
          if (params.data.logoProvider.rawFile) {
            var file = await toBase64(params.data.logoProvider.rawFile);
            params.data.image = { "id": 0, "filename": params.data.logoProvider.rawFile.name, "contentType": params.data.logoProvider.rawFile.type, "sourceValue": file }
          } else {
            params.data.image = { "id": params.data.logoProvider.id, "filename": params.data.logoProvider.filename, "contentType": params.data.logoProvider.contentType, "data": params.data.logoProvider.data };
          }
        }
        if (params.data.locationTax) {
          if (params.data.locationTax.rawFile) {
            var locationTaxFile = await toBase64(params.data.locationTax.rawFile);
            params.data.locationTax = { "id": 0, "filename": params.data.locationTax.rawFile.name, "contentType": params.data.locationTax.rawFile.type, "sourceValue": locationTaxFile }
          } else {
            params.data.locationTax = { "id": params.data.locationTax.id, "filename": params.data.locationTax.filename, "contentType": params.data.locationTax.contentType, "data": params.data.locationTax.data };
          }
        }
      } else if (resource === "discounts") {
        const startDate = computeDate(localStorage.getItem("startDate"));
        const endDate = computeDate(localStorage.getItem("endDate"))
        params.data.startDate = startDate.getDate();
        params.data.endDate = endDate.getDate();
        params.data.startMonth = startDate.getMonth() + 1; // index start from 0
        params.data.endMonth = endDate.getMonth() + 1; // index start from 0
        params.data.startYear = startDate.getFullYear();
        params.data.endYear = endDate.getFullYear();
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        if (role === "ROLE_PROVIDER") {
          params.data.providerId = localStorage.getItem("providerId");
        }
      }
      else if (resource === "indisponibility") {
        const startDate = computeDate(localStorage.getItem("startDate"));
        const endDate = computeDate(localStorage.getItem("endDate"));
        params.data.startDate = startDate.getDate();
        params.data.endDate = endDate.getDate();
        params.data.startMonth = startDate.getMonth() + 1; // index start from 0
        params.data.endMonth = endDate.getMonth() + 1; // index start from 0
        params.data.startYear = startDate.getFullYear();
        params.data.endYear = endDate.getFullYear();
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        if (role === "ROLE_PROVIDER") {
          params.data.providerId = localStorage.getItem("providerId");
        }
      }
      else if (resource === "seasons") {
        const startDate = computeDate(localStorage.getItem("startDate"));
        const endDate = computeDate(localStorage.getItem("endDate"));
        params.data.startDate = startDate.getDate();
        params.data.endDate = endDate.getDate();
        params.data.startMonth = startDate.getMonth() + 1; // index start from 0
        params.data.endMonth = endDate.getMonth() + 1; // index start from 0
        params.data.startYear = startDate.getFullYear();
        params.data.endYear = endDate.getFullYear();
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        if (role === "ROLE_PROVIDER") {
          params.data.providerId = localStorage.getItem("providerId");
        }
      }
      else if (resource === "rentalReservation") {
        var alternatives = {
          carIds: params.data.carIds
        };
        return {
          url: `${API_URL}/api/${resource}/${params.id}`,
          options: { method: "PUT", body: JSON.stringify(alternatives) }
        };

      } else if (resource === "transferReservation") {
        var alternatives = {
          carIds: params.data.carIds
        };
        return {
          url: `${API_URL}/api/${resource}/${params.id}`,
          options: { method: "PUT", body: JSON.stringify(alternatives) }
        };

      }
      return {
        url: `${API_URL}/api/${resource}/${params.id}`,
        options: { method: "PUT", body: JSON.stringify(params.data) }
      };
    case CREATE:
      if (resource === "brands") {
        if (params.data.images && params.data.images.length) {
          params.data.images = await Promise.all(params.data.images.map(async (img) => {
            if (img.rawFile) {
              var file = await toBase64(img.rawFile);
              return { "id": 0, "filename": img.rawFile.name, "contentType": img.rawFile.type, "sourceValue": file }
            } else {
              return { "id": img.id, "filename": img.filename, "contentType": img.contentType, "data": img.data };
            }
          }));
        }
      } if (resource === "brands-transfer") {
        if (params.data.images && params.data.images.length) {
          params.data.images = await Promise.all(params.data.images.map(async (img) => {
            if (img.rawFile) {
              var file = await toBase64(img.rawFile);
              return { "id": 0, "filename": img.rawFile.name, "contentType": img.rawFile.type, "sourceValue": file }
            } else {
              return { "id": img.id, "filename": img.filename, "contentType": img.contentType, "data": img.data };
            }
          }));
        }
      } else if (resource === "providers") {
        if (params.data.logoProvider) {
          if (params.data.logoProvider.rawFile) {
            var file = await toBase64(params.data.logoProvider.rawFile);
            params.data.image = { "id": 0, "filename": params.data.logoProvider.rawFile.name, "contentType": params.data.logoProvider.rawFile.type, "sourceValue": file }
          } else {
            params.data.image = { "id": params.data.logoProvider.id, "filename": params.data.logoProvider.filename, "contentType": params.data.logoProvider.contentType, "data": params.data.logoProvider.data };
          }
        }
        if (params.data.locationTax) {
          if (params.data.locationTax.rawFile) {
            var file = await toBase64(params.data.locationTax.rawFile);
            params.data.locationTax = { "id": 0, "filename": params.data.locationTax.rawFile.name, "contentType": params.data.locationTax.rawFile.type, "sourceValue": locationTaxFile }
          } else {
            params.data.locationTax = { "id": params.data.locationTax.id, "filename": params.data.locationTax.filename, "contentType": params.data.locationTax.contentType, "data": params.data.locationTax.data };
          }
        }
      } else if (resource === "discounts") {
        const startDate = computeDate(localStorage.getItem("startDate"));
        const endDate = computeDate(localStorage.getItem("endDate"))
        params.data.startDate = startDate.getDate();
        params.data.endDate = endDate.getDate();
        params.data.startMonth = startDate.getMonth() + 1; // index start from 0
        params.data.endMonth = endDate.getMonth() + 1; // index start from 0
        params.data.startYear = startDate.getFullYear();
        params.data.endYear = endDate.getFullYear();
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        if (role === "ROLE_PROVIDER") {
          params.data.providerId = localStorage.getItem("providerId");
        }
      }
      else if (resource === "indisponibility") {
        const startDate = computeDate(localStorage.getItem("startDate"));
        const endDate = computeDate(localStorage.getItem("endDate"));
        params.data.startDate = startDate.getDate();
        params.data.endDate = endDate.getDate();
        params.data.startMonth = startDate.getMonth() + 1; // index start from 0
        params.data.endMonth = endDate.getMonth() + 1; // index start from 0
        params.data.startYear = startDate.getFullYear();
        params.data.endYear = endDate.getFullYear();
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        if (role === "ROLE_PROVIDER") {
          params.data.providerId = localStorage.getItem("providerId");
        }
      }
      else if (resource === "seasons") {
        const startDate = computeDate(localStorage.getItem("startDate"));
        const endDate = computeDate(localStorage.getItem("endDate"));
        params.data.startDate = startDate.getDate();
        params.data.endDate = endDate.getDate();
        params.data.startMonth = startDate.getMonth() + 1; // index start from 0
        params.data.endMonth = endDate.getMonth() + 1; // index start from 0
        params.data.startYear = startDate.getFullYear();
        params.data.endYear = endDate.getFullYear();
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
        if (role === "ROLE_PROVIDER") {
          params.data.providerId = localStorage.getItem("providerId");
        }
      }

      return {
        url: `${API_URL}/api/${resource}`,
        options: { method: "POST", body: JSON.stringify(params.data) }
      };
    case DELETE:
      return {
        url: `${API_URL}/api/${resource}/${params.id}`,
        options: { method: "DELETE" }
      };
    case DELETE_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.id })
      };
      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: { method: "DELETE" }
      };
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params
) => {
  const { json } = response;
  switch (type) {
    case GET_LIST:
      return {
        data: json.map(x => x),
        total: 1
      };
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    case DELETE_MANY: {
      return { data: json || [] };
    }
    default:
      return { data: json };
  }
};

export default async (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options = {} } = await convertDataProviderRequestToHTTP(
    type,
    resource,
    params
  );

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('X-Authorization', 'Bearer ' + token);

  return fetchJson(url, options).then(response =>
    convertHTTPResponseToDataProvider(response, type, resource, params)
  );

};