import React, { Fragment } from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Datagrid,
  Responsive,
  DateField,
  BooleanField,
  SimpleShowLayout,
  Show,
  SelectInput,
  Filter,
  ReferenceInput,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  CheckboxGroupInput,
  ReferenceArrayInput,
  NumberField,
  required,
} from "react-admin";
import Button from "@material-ui/core/Button";
import ChatComponent from "./ChatComponent";
import ConfirmationButton from "./ConfirmationButton.js";

const FilterReservations = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Status"
      source="status"
      reference="rentalStatuses"
      allowEmpty={false}
      alwaysOn
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    {localStorage.getItem("role") === "ROLE_ADMIN" && (
      <ReferenceInput
        label="Provider"
        source="provider"
        reference="transfer-providers"
        allowEmpty
        alwaysOn
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
    )}
    <ReferenceInput
      label="Month"
      source="month"
      reference="months"
      allowEmpty
      alwaysOn
    >
      <SelectInput optionText="index" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Year"
      source="year"
      reference="years"
      allowEmpty
      alwaysOn
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const StatusField = ({ record = {} }) => {
  var status = "";
  switch (record.status) {
    case "REQUESTED":
      status = "Requested";
      break;
    case "FROM":
      status = "Starting from";
      break;
    case "ONGOING":
      status = "On going";
      break;
    case "FINISHED":
      status = "Finished";
      break;
    case "CANCELED":
      status = "Canceled";
      break;
    case "ALTERNATIVE_OFFERS":
      status = "Alternative offers";
      break;
    case "CONFIRMED":
      status = "Confirmed";
      break;
    default:
      break;
  }
  return <p class="MuiTypography-body1-122 MuiTypography-root-100">{status}</p>;
};
StatusField.defaultProps = { addLabel: true, label: "Status" };

const DriverNameField = ({ record = {} }) => {
  const role = localStorage.getItem("role");
  if (
    role === "ROLE_ADMIN" ||
    record.status === "CONFIRMED" ||
    record.status === "FINISHED"
  ) {
    return (
      <p class="MuiTypography-body1-122 MuiTypography-root-100">
        {record.passagerFirstName}
      </p>
    );
  }
  return <p class="MuiTypography-body1-122 MuiTypography-root-100">N/A</p>;
};
DriverNameField.defaultProps = { addLabel: true, label: "Passager's name" };

const DriverPhoneField = ({ record = {} }) => {
  const role = localStorage.getItem("role");

  if (
    role === "ROLE_ADMIN" ||
    record.status === "CONFIRMED" ||
    record.status === "FINISHED"
  ) {
    return (
      <p class="MuiTypography-body1-122 MuiTypography-root-100">
        {record.passagerPhone}
      </p>
    );
  }

  return <p class="MuiTypography-body1-122 MuiTypography-root-100">N/A</p>;
};
DriverPhoneField.defaultProps = { addLabel: true, label: "Passager's Phone" };

const DriverEmailField = ({ record = {} }) => {
  const role = localStorage.getItem("role");

  if (
    role === "ROLE_ADMIN" ||
    record.status === "CONFIRMED" ||
    record.status === "FINISHED"
  ) {
    return (
      <p class="MuiTypography-body1-122 MuiTypography-root-100">
        {record.email}
      </p>
    );
  }

  return <p class="MuiTypography-body1-122 MuiTypography-root-100">N/A</p>;
};
DriverEmailField.defaultProps = { addLabel: true, label: "Email" };

export const ListTransferReservations = (props) => (
  <List
    filters={<FilterReservations />}
    filterDefaultValues={{ status: 4 }}
    title="Transfer Bookings"
    {...props}
    bulkActions={false}
    pagination={<PostPagination />}
  >
    <Responsive
      medium={
        <Datagrid rowClick="show">
          <TextField sortable={false} source="codeReservation" label="Code" />

          <DriverNameField sortable={false} source="passagerFirstName" />
          <DriverPhoneField sortable={false} source="passagerPhone" />
          <DriverEmailField sortable={false} source="email" />
          {localStorage.getItem("role") === "ROLE_ADMIN" && (
            <TextField
              sortable={false}
              source="car.provider.name"
              label="Provider"
            />
          )}
          <TextField sortable={false} source="startDate" label="Pick-up Date" />
          <CheckEndDateValidation sortable={false} source="endDate" label="Drop-off Date" />
          <TextField sortable={false} source="car.brand.brand" label="Brand" />
          <TextField sortable={false} source="car.brand.model" label="Model" />
          <TextField
            sortable={false}
            source="pickupLocation.name"
            label="Pick-up"
          />
          <TextField
            sortable={false}
            source="dropOffLocation.name"
            label="Drop-off"
          />

          <NumberField
            sortable={false}
            source="totalPrice"
            label="Total Cost"
            options={{ maximumFractionDigits: 2 }}
          />
        </Datagrid>
      }
    />
  </List>
);

const EmptyActions = ({ basePath, data, resource }) => ""; // do nothing

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Send alternative offers" />
  </Toolbar>
);

const CheckEndDateValidation = ({ record = {} }) => {
  var endDateVerified;
  record.endDate == "27-11-0002 23:44" ?
    endDateVerified = "No return" : endDateVerified = record.endDate;
  return endDateVerified;
}

CheckEndDateValidation.defaultProps = {label: 'Name'};

const OptionsField = ({ record = {} }) => {
  if (record.rezervationOptions && record.rezervationOptions.length)
    return (
      <table>
        <tr align="left">
          <th>Cantitate</th>
          <th>&nbsp;</th>
          <th>Optiune</th>
          <th>&nbsp;</th>
          <th>Pret pe zi</th>
        </tr>
        {record.rezervationOptions.map((option) => {
          return (
            <tr>
              <td>{option.quantity}</td>
              <td />
              <td>{option.option.option.name}</td>
              <td />
              <td>{option.option.pricePerDay}</td>
            </tr>
          );
        })}
      </table>
    );

  return "None";
};

OptionsField.defaultProps = { addLabel: true, label: "Options" };

export const ShowTransferReservations = (props, record = {}) => (
  <Show actions={<EmptyActions />} title="Booking details " {...props}>
    <SimpleShowLayout>
      <StatusWithButtons />
      <StatusField source="status" />
      <BooleanField
        source="clientDidNotShowUp"
        label="Client did not show up?"
      />
      <TextField source="codeReservation" label="Code" />
      <TextField source="days" label="Number of days" />
      <DriverNameField source="passagerFirstName" />
      <DriverPhoneField source="passagerPhone" />
      <DriverEmailField source="email" />
      <TextField source="car.provider.name" label="Provider" />
      <TextField addLabel={true} label="Pick-up Date" source="startDate" />
      <CheckEndDateValidation addLabel={true} label="Drop-off Date" source="endDate" />
      <TextField source="car.brand.brand" label="Brand" />
      <TextField source="car.brand.model" label="Model" />
      <TextField source="pickupLocation.name" label="Pick-up" />
      <TextField source="dropOffLocation.name" label="Drop-off" />
      <TextField source="insurance.name" label="Insurance" />
      <NumberField
        source="deposit"
        label="Deposit"
        options={{ maximumFractionDigits: 2 }}
      />
      <NumberField
        source="totalPrice"
        label="Total Cost"
        options={{ maximumFractionDigits: 2 }}
      />
      <TextField source="info" label="Informatii" />
      <TextField source="cancelReason" label="Cancelation Reason" />
      <ChatComponent />
      <OptionsField />
    </SimpleShowLayout>
  </Show>
);

const AlternativesHeader = ({ record = {} }) => {
  localStorage.setItem("reservationForAlt", record.id);
  return (
    <h4>
      Offer alternatives to {record.passagerFirstName} for rezervation{" "}
      {record.codeReservation}
    </h4>
  );
};

export const TransferRezervationEdit = (props) => (
  <Edit actions={<EmptyActions />} title="Alternative offers" {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="show">
      <AlternativesHeader />
      <ReferenceArrayInput
        validate={required()}
        label="Alternative cars"
        source="carIds"
        reference="carsForAlternatives"
      >
        <CheckboxGroupInput
          optionText="brand.brandWithModel"
          optionValue="id"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const StatusWithButtons = ({ record = {} }) => {
  const changeStatus = (statuz) => {
    const request = new Request(
      "https://api.topcarrentals.ro/api/transferStatuses/" + record.id,
      {
        method: "PUT",
        body: JSON.stringify({ status: statuz }),
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    fetch(request).then((response) => {
      window.location.reload();
    });
  };

  const clientDidNotShow = () => {
    const request = new Request(
      "https://api.topcarrentals.ro/api/transferReservation/" +
        record.id +
        "/clientDidNotShow",
      {
        method: "POST",
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    fetch(request).then((response) => {
      window.location.reload();
    });
  };

  const goToAltOffers = () => {
    document.location.href = "#/transferReservation/" + record.id;
  };

  const role = localStorage.getItem("role");
  if (record.status === "REQUESTED") {
    return (
      <Responsive
        medium={
          <table
            style={{
              float: "right",
            }}
          >
            <tr>
              <td>
                <ConfirmationButton
                  label="Confirm"
                  doAction={() => changeStatus("CONFIRMED")}
                  title="Confirm Booking"
                  content="Are you sure you want to confirm this booking?"
                />
              </td>
              <td>
                <ConfirmationButton
                  label="Cancel"
                  doAction={() => changeStatus("CANCELED")}
                  title="Cancel Booking"
                  content="Are you sure you want to cancel this reservation?"
                />
              </td>
            </tr>
          </table>
        }
        small={
          <table>
            <tr>
              <ConfirmationButton
                label="Confirm"
                doAction={() => changeStatus("CONFIRMED")}
                title="Confirm booking"
                content="Are you sure to confirm this reservation?"
              />{" "}
            </tr>
            <tr>
              <ConfirmationButton
                label="Cancel"
                doAction={() => changeStatus("CANCELED")}
                title="Cancel Booking"
                content="Are you sure to cancel this reservation?"
              />
            </tr>
          </table>
        }
      />
    );
  } else if (record.status === "ALTERNATIVE_OFFERS") {
    return (
      <Responsive
        medium={
          <table
            style={{
              float: "right",
            }}
          >
            <tr>
              <td>
                <ConfirmationButton
                  label="Cancel"
                  doAction={() => changeStatus("CANCELED")}
                  title="Cancel Booking"
                  content="Are you sure to cancel this reservation??"
                />
              </td>
            </tr>
          </table>
        }
        small={
          <ConfirmationButton
            label="Cancel"
            doAction={() => changeStatus("CANCELED")}
            title="Cancel Booking"
            content="Are you sure to cancel this reservation??"
          />
        }
      />
    );
  } else if (record.status === "ONGOING" && !record.clientDidNotShowUp) {
    return (
      <Responsive
        medium={
          <table
            style={{
              float: "right",
            }}
          >
            <tr>
              <td>
                <ConfirmationButton
                  label="Client didn't show up"
                  doAction={() => clientDidNotShow()}
                  title="Client didn't show up"
                  content="Are you sure that this customer did not show up?"
                />
              </td>
            </tr>
          </table>
        }
        small={
          <ConfirmationButton
            label="Client didn't show up"
            doAction={() => clientDidNotShow()}
            title="Client didn't show up"
            content="Are you sure that this customer did not show up?"
          />
        }
      />
    );
  } else {
    return <br />;
  }
};
