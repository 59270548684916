import React from "react";
import PostPagination from "./pagination.js";
import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  Responsive,
  required,
  Toolbar,
  SaveButton
} from "react-admin";

export const ListLocation = props => (
  <List title="Locatii" {...props} bulkActions={false} pagination={<PostPagination />}>
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <TextField sortable={false} source="name" textAlign="left" label="Nume" />
        </Datagrid>
      }
    />
  </List>
);

const LocationName = ({ record }) => {
  return <span>Administreaza {record ? `${record.name}` : ""}</span>;
};

const CustomToolbar = props => (
  <Toolbar {...props}>
      <SaveButton />
  </Toolbar>
);

export const LocationEdit = props => (
  <Edit title={<LocationName />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list">
      <TextInput validate={required()} source="name" label="Nume"  />
    </SimpleForm>
  </Edit>
);

export const LocationCreate = props => (
  <Create title="Add location" {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} source="name" label="Nume"  />
    </SimpleForm>
  </Create>
);
