import React from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Edit,
  Create,
  Datagrid,
  SimpleForm,
  DateField,
  DateInput,
  SelectInput,
  NumberField,
  NumberInput,
  Responsive,
  SelectArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  required,
  number,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export const ListDiscounts = (props) => (
  <List
    title="Reduceri"
    {...props}
    bulkActions={false}
    pagination={<PostPagination />}
  >
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <NumberField
            sortable={false}
            source="discountPercentage"
            label="Procent reducere"
          />
          <StartDateField
            sortable={false}
            source="startDate"
            label="Data incepere"
          />
          <EndDateField
            sortable={false}
            source="endDate"
            label="Data incheiere"
          />
          <TextField sortable={false} label="Furnizor" source="provider.name" />
          <TextField
            sortable={false}
            label="Clasa Masina"
            source="brandResp.name"
          />
        </Datagrid>
      }
    />
  </List>
);

const DiscountName = ({ record }) => {
  // Prepare data in title compute
  record.providerId = record.provider.id;
  record.brandId = record.brandResp.id;
  return (
    <span>
      Administreaza{" "}
      {record
        ? `${record.discountPercentage}% reducere pentru ${record.provider.name}`
        : ""}
    </span>
  );
};

const EndDateInput = ({ record = {} }) => {
  var thisDate = record.endDate
    ? new Date(record.endDate.slice(0, 19))
    : new Date();
  localStorage.setItem("endDate", thisDate.toDateString());
  const [selectedDate, setSelectedDate] = React.useState(thisDate);
  const handleDateChange = (date) => {
    record.endDate = date.toJSON();
    setSelectedDate(date);
    localStorage.setItem("endDate", date.toDateString());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        margin="normal"
        label="Data incheiere"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
};

const StartDateInput = ({ record = {} }) => {
  var thisDate = record.startDate
    ? new Date(record.startDate.slice(0, 19))
    : new Date();
  localStorage.setItem("startDate", thisDate.toDateString());
  const [selectedDate, setSelectedDate] = React.useState(thisDate);
  const handleDateChange = (date) => {
    record.startDate = date.toJSON();
    setSelectedDate(date);
    localStorage.setItem("startDate", date.toDateString());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        margin="normal"
        label="Data incepere"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
};

const EndDateField = ({ record = {} }) => {
  if (!record.endDate || !record.endDate.length) return "";

  return (
    <p class="MuiTypography-body1-122 MuiTypography-root-100">
      {format(new Date(record.endDate.slice(0, 19)), "dd/MM/yyyy")}
    </p>
  );
};

const StartDateField = ({ record = {} }) => {
  if (!record.startDate || !record.startDate.length) return "";

  return (
    <p class="MuiTypography-body1-122 MuiTypography-root-100">
      {format(new Date(record.startDate.slice(0, 19)), "dd/MM/yyyy")}
    </p>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
);

export const DiscountEdit = (props) => (
  <Edit title={<DiscountName />} {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <NumberInput
        validate={[required(), number()]}
        source="discountPercentage"
        label="Procentaj reducere"
      />
      <StartDateInput
        validate={required()}
        source="startDate"
        label="Data incepere"
      />
      <EndDateInput
        validate={required()}
        source="endDate"
        label="Data incheiere"
      />
      {localStorage.getItem("role") === "ROLE_ADMIN" && (
        <ReferenceInput
          validate={required()}
          label="Furnizor"
          source="providerId"
          reference="providers"
        >
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
      )}
      <ReferenceInput
        validate={required()}
        label="Clasa Masina"
        source="brandId"
        reference="carClasses"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const DiscountCreate = (props) => (
  <Create title="Adauga reducere" {...props}>
    <SimpleForm redirect="list">
      <NumberInput
        validate={[required(), number()]}
        source="discountPercentage"
        label="Procentaj reducere"
      />
      <StartDateInput
        validate={required()}
        source="startDate"
        label="Data incepere"
      />
      <EndDateInput
        validate={required()}
        source="endDate"
        label="Data incheiere"
      />
      {localStorage.getItem("role") === "ROLE_ADMIN" && (
        <ReferenceInput
          validate={required()}
          label="Furnizor"
          source="providerId"
          reference="providers"
        >
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
      )}
      <ReferenceArrayInput
        validate={required()}
        label="Clasa Masina"
        source="brandId"
        reference="carClasses"
      >
        <SelectArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
