import React, { useState, useEffect } from "react";
import PostPagination from "./pagination.js";
import companyLogo from "./desc.jpeg";
import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  Responsive,
  required,
  Toolbar,
  SaveButton
} from "react-admin";

export const ListLocationTransfers = props => {

  const [url, setUrl] = useState(null);
  const mystyle = {
    margin: "15px",
    textDecoration: "none",
    color: "#2196f3",
  };
  const imageStyle = {
    height: "20px",
    width: "20px",
    margin:"0 5px 0 0"
  };


  useEffect(() => {
    setUrl("https://api.topcarrentals.ro/exportTransferLocations");
}, []);


  return (
    <>
      <a
      style={mystyle}
      href={url}
    >
      <img style={imageStyle} src={companyLogo} />
      <span>Export</span>
    </a>
      <List title="Locatii" {...props} bulkActions={false} pagination={<PostPagination />}>
        <Responsive
          medium={
            <Datagrid rowClick="edit">
              <TextField sortable={false} source="name" textAlign="left" label="Nume" />
            </Datagrid>
          }
        />
      </List>
    </>
  )

};

const LocationName = ({ record }) => {
  return <span>Administreaza {record ? `${record.name}` : ""}</span>;
};

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const LocationEditTransfers = props => (
  <Edit title={<LocationName />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list">
      <TextInput validate={required()} source="name" label="Nume" />
    </SimpleForm>
  </Edit>
);

export const LocationCreateTransfers = props => {
  const [file, setFile] = useState(null);

  return (
    <>

      <form action="https://api.topcarrentals.ro/importTransferLocations" method="post" enctype="multipart/form-data">
        <input id="fileSelect" name="importFile" type="file" onChange={(e) => setFile(e.target.files[0])} accept=".csv" />
        <button type="submit">Submit</button>
      </form>

      <Create title="Add location" {...props}>
        <SimpleForm redirect="list">
          <TextInput validate={required()} source="name" label="Nume" />
        </SimpleForm>
      </Create>
    </>
  )
};
