import React from "react";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button  from "@material-ui/core/Button";

const boxProps = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1
};

class ChatComponent extends React.Component {
  reservationId;
  providerName;
  content;
  mailTo;
  message = '';

  constructor(props) {
      super(props);
      this.reservationId = this.props.record["id"];
      this.providerName = this.props.record["car"].provider.name;
      this.content="<div>"+
      "<p>Hello,</p>"+
      "<br><br>"+
      "<p>You have received a message from the provider regarding the reservation nr."+
      this.props.record["codeReservation"]+
      "<br><br>"+
      '<a style="color: black;" href="https://en.topcarrentals.ro/details-reservation.html?id=' +
      this.props.record["id"]+
     '">View the reservation in your account</a>' +
     "<br><br>"+
     "<p>Thank you!</p>" +
     "<br><br>"+
     "<p>TopCarRentals.ro</p>" +
     "</div>";
      this.mailTo=this.props.record["email"];
      this.state = {
          chatEntries: []
      };
      this.loadChatEntries();  
  }

  loadChatEntries() {
      const request = new Request("https://api.topcarrentals.ro/chats/" + this.reservationId, {
          method: "GET",
          headers: { "Content-type": "application/json; charset=UTF-8" }
      });
      fetch(request)
          .then(response => response.json())
          .then(response => {
              this.setState({chatEntries: response});
          });
  };

  sendMessage = (message) => {
    const request = new Request("https://api.topcarrentals.ro/chats/" + this.reservationId + "/provider", {
        method: "POST",
        body: JSON.stringify({ "value": message }),
        headers: { "Content-type": "application/json; charset=UTF-8" }
      });
      fetch(request)
        .then(response => {
          this.sendMail();
          window.location.reload();
        });
  };
  sendMail = () => {
    const request = new Request("https://api.topcarrentals.ro/mail/", {
        method: "POST",
        body: JSON.stringify({
          subject: "New Message",
          text: this.content,
          to: this.mailTo,
        }),
        headers: { "Content-type": "application/json; charset=UTF-8" }
      });
      fetch(request)
        .then(response => {
        });
  };
  render() {
    return (
      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <h3>Messages</h3>
        <Container>
          { 
            this.state.chatEntries.map((entry) => {
              return <List>
                <ListItem>
                  <ListItemText primary={entry.content} secondary={entry.user ? "Client" : this.providerName}/>
                </ListItem>
                <Divider />
              </List>
            }) 
          }
          <br/><br/>
          <Input fullWidth={true} onChange={event => this.message = event.target.value} type="text" placeholder="Scrie un mesaj" />
          
          <Button label="Trimite" variant="contained" color="primary" onClick={e => this.sendMessage(this.message) }>Send</Button>
        </Container>
      </Box>
    )
  }
}
  
export default ChatComponent;
    
