import React, { Fragment } from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Datagrid,
  Responsive,
  DateTimeInput,
  BooleanField,
  SimpleShowLayout,
  Show,
  SelectInput,
  AutocompleteInput,
  TextInput,
  NumberInput,
  Filter,
  BooleanInput,
  ReferenceInput,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  CheckboxGroupInput,
  ReferenceArrayInput,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
  NumberField,
  Create,
  required
} from "react-admin";
import Button  from "@material-ui/core/Button";
import ChatComponent from "./ChatComponent";
import ConfirmationButton from "./ConfirmationButton.js";
import { string } from "prop-types";
import { RichTextInput } from "ra-input-rich-text";

const FilterReservations = (props) => (
  <Filter {...props}>
      <ReferenceInput label="Status" source="status" reference="rentalStatuses" allowEmpty={false} alwaysOn>
          <SelectInput optionText="name" optionValue="id"/>
      </ReferenceInput>
      {
        localStorage.getItem("role") === 'ROLE_ADMIN' &&
            <ReferenceInput label="Furnizor" source="provider" reference="providers" allowEmpty alwaysOn>
            <SelectInput optionText="name" optionValue="id"/>
        </ReferenceInput>
      }
      <ReferenceInput label="Luna" source="month" reference="months" allowEmpty alwaysOn>
          <SelectInput optionText="index" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput label="An" source="year" reference="years" allowEmpty alwaysOn>
          <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
  </Filter>
);

const StatusField = ({ record = {} }) => {
  var status = '';
  switch(record.status) {
    case 'REQUESTED':
      status = 'In asteptare';
      break;
    case 'FROM':
      status = 'Incepute in';
      break;
    case 'ONGOING':
      status = 'In derulare';
      break; 
    case 'FINISHED':
      status = 'Finalizat';
      break; 
    case 'CANCELED':
      status = 'Anulat';
      break;
    case 'ALTERNATIVE_OFFERS':
      status = 'Oferte alternative';
      break; 
    case 'CONFIRMED':
      status = 'Confirmat';
      break; 
    default:
      break;
  }
  return (<p class="MuiTypography-body1-122 MuiTypography-root-100">{status}</p>);
}
StatusField.defaultProps = { addLabel: true, label: 'Status' };

const DriverNameField = ({ record = {} }) => {
    const role = localStorage.getItem("role");
    if (role === "ROLE_ADMIN" || record.status === "CONFIRMED" || record.status === "FINISHED") {
      return (
          <p class="MuiTypography-body1-122 MuiTypography-root-100">{record.driverName}</p>
      );
    }
    return (<p class="MuiTypography-body1-122 MuiTypography-root-100">N/A</p>);
}
DriverNameField.defaultProps = { addLabel: true, label: 'Nume Sofer' };

const DriverPhoneField = ({ record = {} }) => {
  const role = localStorage.getItem("role");

  if (role === "ROLE_ADMIN" || record.status === "CONFIRMED" || record.status === "FINISHED") {
    return (
        <p class="MuiTypography-body1-122 MuiTypography-root-100">{record.driverPhone}</p>
    );
  }

  return (<p class="MuiTypography-body1-122 MuiTypography-root-100">N/A</p>);
}
DriverPhoneField.defaultProps = { addLabel: true, label: 'Telefon Sofer' };

const DriverEmailField = ({ record = {} }) => {
  const role = localStorage.getItem("role");

  if (role === "ROLE_ADMIN" || record.status === "CONFIRMED" || record.status === "FINISHED") {
    return (
      <p class="MuiTypography-body1-122 MuiTypography-root-100">{record.email}</p>
    );
  }

  return (<p class="MuiTypography-body1-122 MuiTypography-root-100">N/A</p>);
}
DriverEmailField.defaultProps = { addLabel: true, label: 'Email' };

export const ListRentalReservations = props => (
  <List filters={< FilterReservations/>} filterDefaultValues={{ status: 4 }} title="Rezervari" {...props} bulkActions={false} pagination={<PostPagination />}>
    <Responsive
      medium={
        <Datagrid rowClick="show">
          <TextField sortable={false} source="codeReservation" label="Cod" />
          <BooleanField source="clientDidNotShowUp" label="Clientul nu s-a prezentat?"/>
          <DriverNameField sortable={false} source="driverName"/>
          <DriverPhoneField sortable={false} source="driverPhone" />
          <DriverEmailField sortable={false} source="email" />
          {
            localStorage.getItem("role") === 'ROLE_ADMIN' &&
              <TextField sortable={false} source="car.provider.name" label="Furnizor" />
          }
          <TextField sortable={false} source="startDate" label="Data preluare"  />
          <TextField sortable={false} source="endDate" label="Data predare"  />
          <TextField sortable={false} source="car.brand.brand" label="Marca" />
          <TextField sortable={false} source="car.brand.model" label="Model" />
          <TextField sortable={false} source="pickupLocation.name" label="Preluare"/>
          <TextField sortable={false} source="dropOffLocation.name" label="Predare" />
          <TextField sortable={false} source="insurance.name" label="Asigurare" />
          <NumberField sortable={false} source="deposit" label="Depozit"  options={{ maximumFractionDigits: 2 }}/>
          <NumberField sortable={false} source="totalPrice" label="Cost Total"  options={{ maximumFractionDigits: 2 }}/>
        </Datagrid>
      }
    />
  </List>
);


const EmptyActions = ({ basePath, data, resource }) => (
  "" // do nothing
);

const CustomToolbar = props => (
  <Toolbar {...props}>
      <SaveButton label="Trimite oferte alternative" />
  </Toolbar>
);

const OptionsField = ({ record = {} }) => {
  if (record.rezervationOptions && record.rezervationOptions.length)
    return (
      <table>
      <tr align="left">
        <th>Cantitate</th>
        <th>&nbsp;</th>
        <th>Optiune</th>
        <th>&nbsp;</th>
        <th>Pret pe zi</th>
      </tr>
      { 
          record.rezervationOptions.map((option) => {
            return <tr><td>{option.quantity}</td><td/><td>{option.option.option.name}</td><td/><td>{option.option.pricePerDay}</td></tr>
          }) 
        }
      </table>
    )

    return "None"
}

OptionsField.defaultProps = { addLabel: true, label: 'Optiuni' };

export const ShowRentalReservations = props => (
  <Show actions={<EmptyActions />} title="Detalii rezervare" {...props}>
      <SimpleShowLayout>
          <StatusWithButtons />
          <StatusField source="status" />
          <BooleanField source="clientDidNotShowUp" label="Clientul nu s-a prezentat?"  />
          <TextField source="codeReservation"  label="Cod" />
          <TextField source="days" label="Numar zile" />
          <DriverNameField source="driverName"/>
          <DriverPhoneField source="driverPhone" />
          <DriverEmailField source="email" />
          <TextField source="car.provider.name" label="Furnizor" />
          <TextField addLabel={true} label="Data preluare" source="startDate" />
          <TextField addLabel={true} label="Data predare" source="endDate" />
          <TextField source="car.brand.brand" label="Marca" />
          <TextField source="car.brand.model" label="Model" />
          <TextField source="pickupLocation.name" label="Preluare"/>
          <TextField source="dropOffLocation.name" label="Predare" />
          <TextField source="insurance.name" label="Asigurare" />
          <NumberField source="deposit" label="Depozit" options={{ maximumFractionDigits: 2 }}/>
          <NumberField source="totalPrice" label="Cost total" options={{ maximumFractionDigits: 2 }} />
          <TextField source="cancelReason" label="Motivul anularii" />
          <OptionsField/>
          <ChatComponent/>
      </SimpleShowLayout>
  </Show>
);

const AlternativesHeader = ({ record = {} }) => {
    localStorage.setItem("reservationForAlt", record.id)
    return (
    <h4>Offer alternatives to {record.driverName} for rezervation {record.codeReservation}</h4>
    )
}

export const RezervationEdit = props => (
  <Edit actions={<EmptyActions />} title="Ofera alternative" {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="show">
      <AlternativesHeader />
      <ReferenceArrayInput validate={required()} label="Masini alternative" source="carIds" reference="carsForAlternatives">
        <CheckboxGroupInput optionText="brand.brandWithModel" optionValue="id" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);



export const RentalReservationCreate = props => (
  <Create label="" title="Add Rezervation" {...props}>
        <SimpleForm redirect="list" >
            <DateTimeInput label="Data start" source="startDate" />
            <DateTimeInput label="Data end" source="endDate" />
            <ReferenceInput label="Masina" source="carId" reference="cars">
              <SelectInput optionValue="id" optionText="carWithInfo" />
            </ReferenceInput>
            <TextInput label="Email" source="email" />
            <TextInput label="Numele soferului" source="driverName" />
            <TextInput label="Numarul soferului" source="driverPhone" />
            <ReferenceInput label="Locatie pickup" source="locationPickUpId" reference="locations">
            <SelectInput optionValue="id" optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Locatie dropoff" source="locationDropOffId" reference="locations">
              <SelectInput optionValue="id" optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Asigurare" source="insuranceId" reference="insurances">
              <SelectInput optionValue="id" optionText="name" />
            </ReferenceInput>
            <NumberInput label="Cost total" source="totalPrice" />
            <NumberInput label="Depozit" source="deposit" />
            <NumberInput label="Zile" source="days" />
            <TextInput source="info" label="Informatii aditionale" />
        </SimpleForm>
      </Create>  
)



const StatusWithButtons = ({ record = {} }) => {
  const changeStatus = (statuz) => {
    const request = new Request("https://api.topcarrentals.ro/api/rentalStatuses/" + record.id, {
        method: "PUT",
        body: JSON.stringify({ "status": statuz }),
        headers: { "X-Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-type": "application/json; charset=UTF-8" }
      });
      fetch(request)
        .then(response => {
          window.location.reload();
        });
  }

  const clientDidNotShow = () => {
    const request = new Request("https://api.topcarrentals.ro/api/rentalReservation/" + record.id + "/clientDidNotShow", {
        method: "POST",
        headers: { "X-Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-type": "application/json; charset=UTF-8" }
      });
      fetch(request)
        .then(response => {
          window.location.reload();
        });
  }

  const cancelRezervationWOMail = () => {
    const request = new Request("https://api.topcarrentals.ro/api/rentalReservation/" + record.id + "/cancelRezervation", {
      method: "POST",
      headers: { "X-Authorization": "Bearer " + localStorage.getItem("token"),
        "Content-type": "application/json; charset=UTF-8" }
    });
    fetch(request)
      .then(response => {
        window.location.reload();
      });
  }


  const goToAltOffers = () => {
    document.location.href = '#/rentalReservation/' + record.id;
  }

  const role = localStorage.getItem("role");
  if (record.status === "REQUESTED") {
    return (
      <Responsive
          medium={
            <table style={{
                float: "right"
            }}>
              <tr>
                <td>
                  <ConfirmationButton label="Confirma" doAction={() => changeStatus("CONFIRMED")} title="Confirma rezervarea" content="Sunteti sigur ca vreti sa confirmati aceasta rezervare?" /> 
                </td>
                <td>
                  <ConfirmationButton label="Anuleaza" doAction={() => changeStatus("CANCELED")} title="Anulare rezervare" content="Sunteti sigur ca vreti sa anulati aceasta rezervare?" /> 
                </td>
                {/* <td>
                  <Button variant="outlined" label="Ofera Alternative" onClick={goToAltOffers} >Ofera Alternative</Button>
                </td> */}
              </tr>
            </table>
          }
          small={
            <table>
              <tr>
                  <ConfirmationButton label="Confirma" doAction={() => changeStatus("CONFIRMED")} title="Confirma rezervarea" content="Sunteti sigur ca vreti sa confirmati aceasta rezervare?" /> </tr>
              <tr>
                  <ConfirmationButton label="Anuleaza" doAction={() => changeStatus("CANCELED")} title="Anulare rezervare" content="Sunteti sigur ca vreti sa anulati aceasta rezervare?" /> 
              </tr>
              {/* <tr><Button variant="outlined" label="Ofera Alternative" onClick={goToAltOffers} >Ofera Alternative</Button></tr> */}
            </table>
          }
        />
    )
  } else if (record.status === "ALTERNATIVE_OFFERS") {
    return (
      <Responsive
              medium={
                <table style={{
                  float: "right"
                }}>
                  <tr>
                    <td>
                      <ConfirmationButton label="Anuleaza" doAction={() => changeStatus("CANCELED")} title="Anulare rezervare" content="Sunteti sigur ca vreti sa anulati aceasta rezervare?" /> 
                  </td>
                </tr>
              </table>
          }
          small={
            <ConfirmationButton label="Anuleaza" doAction={() => changeStatus("CANCELED")} title="Anulare rezervare" content="Sunteti sigur ca vreti sa anulati aceasta rezervare?" /> 
          }
        />
    )
  } else if (record.status === "ONGOING" && !record.clientDidNotShowUp) {
    return (
      <Responsive
          medium={
            <table style={{
                float: "right"
            }}>
              <tr>
                <td>
                  <ConfirmationButton label="Clientul nu s-a prezentat" doAction={() => clientDidNotShow()} title="Clientul nu s-a prezentat" content="Sunteti sigur ca acest client nu s-a prezentat?" /> 
                </td>
              </tr>
              <td>
                {
                  role === "ROLE_ADMIN" ? <ConfirmationButton label="Anuleaza rezervarea fara mail" doAction={() => cancelRezervationWOMail()} title="Anuleaza rezervarea" content="Suntenti sigur ca doriti sa anulati rezervarea ?" /> : null
                }
              </td>
            </table> 
          }
          small={
            <ConfirmationButton label="Clientul nu s-a prezentat" doAction={() => clientDidNotShow()} title="Clientul nu s-a prezentat" content="Sunteti sigur ca acest client nu s-a prezentat?" /> 
          }
        />
      
    )
  } else {
    return (<br/>);
  }


}