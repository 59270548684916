import React from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  NumberField,
  Responsive,
  SimpleList,
  BooleanInput,
  BooleanField,
  NumberInput,
  SelectInput,
  ImageField,
  ImageInput,
  Toolbar,
  SaveButton,
  ReferenceInput,
  required,
  number
} from "react-admin";

export const ListBrands = props => (
  <List {...props} title="Sabloane masini" bulkActions={false} pagination={<PostPagination />}>
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <TextField sortable={false} source="brand" label="Marca"  />
          <TextField sortable={false} source="model" label="Model" />
          <TextField sortable={false} source="fuel" label="Combustibil" />
          <TextField sortable={false} source="gear" label="Cutie viteze" />
          <BooleanField sortable={false} source="airConditioning" label="Aer conditionat" />
          <NumberField sortable={false} source="seats" label="Numar locuri" />
          <NumberField sortable={false} source="doors" label="Numar usi" />
          <NumberField sortable={false} source="luggage" label="Bagaje" />
          <TextField sortable={false} source="carClass" label="Clasa masina" />
        </Datagrid>
      }
    />
  </List>
);

const BrandName = ({ record }) => {
  return <span>Sablon {record ? `${record.brand} ${record.model}` : ""}</span>;
};

const CustomToolbar = props => (
  <Toolbar {...props}>
      <SaveButton />
  </Toolbar>
);

export const BrandEdit = props => (
  <Edit title={<BrandName />} {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <TextInput validate={required()} source="brand" label="Marca" />
      <TextInput validate={required()} source="model" label="Model"  />
      <ReferenceInput validate={required()} label="Combustibil" source="fuel" reference="fuel">
          <SelectInput optionValue="name" optionText="name" />
      </ReferenceInput>
      <ReferenceInput validate={required()} label="Cutie viteze" source="gear" reference="transmissions">
          <SelectInput optionValue="name" optionText="name" />
      </ReferenceInput>
      <BooleanInput source="airConditioning" label="Aer conditionat" />
      <NumberInput validate={[required(), number()]} source="seats" label="Numar locuri"  />
      <NumberInput validate={[required(), number()]} source="doors" label="Numar usi"  />
      <NumberInput validate={[required(), number()]} source="luggage" label="Bagaje"  />
      <ReferenceInput validate={required()} label="Clasa masina" source="carClassId" reference="carClasses">
          <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <ImageInput validate={required()} multiple source="images" accept="image/*" label="Imagini" >
        <ImageField source="sourceValue" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const BrandCreate = props => (
  <Create title="Adauga sablon" {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} source="brand" label="Marca"  />
      <TextInput validate={required()} source="model"  label="Model" />
      <ReferenceInput validate={required()} defaultValue="N/A" label="Combustibil" source="fuel" reference="fuel">
          <SelectInput optionValue="name" optionText="name" />
      </ReferenceInput>
      <ReferenceInput validate={required()} label="Cutie viteze" source="gear" reference="transmissions">
          <SelectInput optionValue="name" optionText="name" />
      </ReferenceInput>
      <BooleanInput source="airConditioning" defaultValue={true}/>
      <NumberInput validate={[required(), number()]}  source="seats" label="Numar locuri"  />
      <NumberInput validate={[required(), number()]}  source="doors" label="Numar usi"  />
      <NumberInput validate={[required(), number()]}  source="luggage" label="Bagaje"  />
      <ReferenceInput validate={required()} label="Clasa masina" source="carClassId" reference="carClasses">
          <SelectInput optionText="name" optionValue="id"/>
      </ReferenceInput>
      <ImageInput validate={required()} multiple source="images" accept="image/*" label="Imagini" >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
