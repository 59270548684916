import React from "react";
import PostPagination from "./pagination.js";

import {
  List,
  TextField,
  Edit,
  DisabledInput,
  TextInput,
  Create,
  Datagrid,
  SimpleForm,
  BooleanField,
  BooleanInput,
  NumberField,
  NumberInput,
  ImageField,
  ImageInput,
  FileField,
  FileInput,
  SimpleList,
  Responsive,
  required,
  email,
  number,
  Toolbar,
  SaveButton,
  SelectArrayInput,
  ReferenceArrayInput,
  SelectInput
} from "react-admin";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import RichTextInput from 'ra-input-rich-text';

import CustomImageField from "./CustomLogoImageField";

const boxProps = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1
};

export const ListProviders = props => (
  <List title="Furnizori" {...props} bulkActions={false} pagination={<PostPagination />}>
    <Responsive
      medium={
        <Datagrid rowClick="edit">
          <CustomImageField source="logo"/>
          <TextField source="name" label="Nume"  />
          <TextField source="cui" label="CUI"  />
          <TextField source="regNr" label="Nr. inreg"  />
          <TextField source="mail" label="Email"  />
          <TextField source="address" label="Adresa"  />
          <BooleanField source="additionalDriver" label="Sofer aditional"  />
          <BooleanField source="cash" />
          <NumberField source="limitedKm"  label="Limita km" />
          <BooleanField source="meetAndGreet" />
          <NumberField source="crossBorderTax" label="Taxa frontiera"  options={{ maximumFractionDigits: 2 }}/>
          <NumberField source="minimumAge" label="Varsta minima"  />
          <NumberField source="dislocationCost" label="Cost dislocare"  options={{ maximumFractionDigits: 2 }}/>
        </Datagrid>
      }
    />
  </List>
);

const ProviderName = ({ record }) => {
  if (record.cardTypes)
    record.cardTypes = record.cardTypes.map(function(cardType) {
      return cardType.id ? cardType.id : cardType;
    });
  return <span>Administreaza {record ? `${record.name}` : ""}</span>;
};

const CustomToolbar = props => (
  <Toolbar {...props}>
      <SaveButton />
  </Toolbar>
);

export const ProviderEdit = props => (
  <Edit title={<ProviderName />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list">
      <TextInput validate={required()} source="name" label="Nume"  />
      <TextInput validate={required()} source="cui" label="CUI"  />
      <TextInput source="regNr" label="Nr. inreg"  />
      <TextField source="mail" label="Email"  />
      <TextInput source="address" label="Adresa"  />
      <ReferenceArrayInput label="Tipuri card acceptate" source="cardTypes" reference="cardTypes">
          <SelectArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <h3>Politici</h3>
            <table>
              <tr>
                <td><NumberInput validate={[number()]} source="crossBorderTax" label="Taxa frontiera" helperText="Gratuit in cazul valorii 0." /></td>
                <td/>
                <td><BooleanInput source="canCrossBorder" label="Accepta trecerea granitei"/></td>
              </tr>
            </table>
            <NumberInput validate={[number()]} source="crossBorderKmLimit" label="Limita max in afara tarii" helperText="Nelimitat in cazul valorii 0." />
            <NumberInput validate={[number()]} source="limitedKm" label="Limita kilometri" helperText="Nelimitat in cazul valorii 0." />
            <NumberInput validate={[number()]} source="dislocationCost" label="Cost dislocare"  helperText="Gratuit in cazul valorii 0."/>
            <NumberInput validate={[number()]} source="roadTollPrice" label="Taxa drum"  helperText="Gratuit in cazul valorii 0." />
            <NumberInput validate={[number()]} source="minLicensePeriod" label="Experienta minima(permis)" helperText="Nu se aplica in cazul valorii 0."/>
            <NumberInput validate={[number()]} source="minimumAge" label="Varsta minima"   helperText="Nu se aplica in cazul valorii 0."/>
            <BooleanInput source="fullToFull"  label="Plin la plin"  />
            <BooleanInput source="meetAndGreet" />
            <BooleanInput source="freeAdditionalDriver" label="Sofer aditional gratuit"   />
            <BooleanInput source="fullPaymentAtPickup" label="Achitare la preluare" />
            <BooleanInput source="freeChildChair" label="Scaun copil gratuit" />
            <BooleanInput source="freeCancelling" label="Anulare gratuita inainte cu 48 de ore" />
            <BooleanInput source="freeModification" label="Modificare gratuita a rezervarii" />
            <BooleanInput source="canReduceSecondInsuranceDeposit" label="Posibilitate de reducere a depozitului"   />
            <BooleanInput source="canExcludeThirdInsuranceDeposit" label="Posibilitate de excludere a depozitului"  />
            <BooleanInput source="cash" label="Accepta plata cash"/>
            <BooleanInput source="cashDeposit" label="Accepta depozit cash"/>
            <BooleanInput source="debitCardDeposit" label="Accepta depozit cu card debit"/>
            <BooleanInput source="creditCardDeposit" label="Accepta depozit cu card credit"/>
            <FileInput source="locationTax" label="Taxe locatii" multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                <FileField source="sourceValue" title="filename" />
            </FileInput>
          </Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <h3>Optiuni</h3>
            <table>
              <tr>
                <td><NumberInput validate={[number()]} source="firstChildChairPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasFirstChildChair" label="Scaun copil 0-0.5 ani" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="secondChildChairPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasSecondChildChair" label="Scaun copil 0.5-3 ani" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="thirdChildChairPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasThirdChildChair" label="Scaun copil peste 3 ani" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="gpsPrice" label="Pret pe zi"/></td>
                <td/>
                <td><BooleanInput source="hasGps" label="GPS" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="snowChainsPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasSnowChains" label="Lanturi de zapada"/></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="additionalDriverPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasAdditionalDriver" label="Sofer aditional" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="wifiRouterPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasWifiRouter" label="Wi-Fi Router" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="skiSupportPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasSkiSupport" label="Suport ski" /></td>
              </tr>
            </table>
          </Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <h3>Detalii preluare si predare</h3>
            <SelectInput label="Mod taxa" source="taxMode" choices={[
                { id: '0', name: 'Gratuit' },
                { id: '1', name: 'Tot timpul aplicabila' },
                { id: '2', name: 'Taxa in afara orarului' },
                { id: '3', name: 'Taxa in afara orarului si pe tot parcursul weekend-ului' }
              ]} />
            <TextInput source="openingTime" placeholder="9:00" label="Deschidere" />
            <TextInput source="closingTime" placeholder="17:00" label="Inchidere" />
            <NumberInput validate={[number()]} source="pickupCost" label="Taxa preluare"  />
            <NumberInput validate={[number()]} source="dropoffCost" label="Taxa predare"  />
          </Grid>
        </Container>
      </Box>

      <ImageInput source="logoProvider" label="Logo"  multiple={false} accept="image/*">
          <ImageField source="sourceValue" title="filename" />
      </ImageInput>
      <RichTextInput fullWidth={true} source="policy" label="Politica"  />
      <RichTextInput fullWidth={true} source="policyEn" label="Politica(engleza)"  />
    </SimpleForm>
  </Edit>
);

export const ProviderCreate = props => (
  <Create title="Adauga furnizor" {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} source="name" label="Nume" />
      <TextInput validate={required()} source="cui" label="CUI" />
      <TextInput source="regNr" label="Nr. inreg" />
      <TextInput validate={[required(), email()]} source="mail" label="Email" />
      <TextInput source="address" label="Adresa" />
      <ReferenceArrayInput label="Tipuri card acceptate" source="cardTypes" reference="cardTypes">
          <SelectArrayInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <h3>Policies</h3>
            <table>
              <tr>
                <td><NumberInput validate={[number()]} source="crossBorderTax" label="Taxa frontiera" helperText="Gratuit in cazul valorii 0." /></td>
                <td/>
                <td><BooleanInput source="canCrossBorder" label="Accepta trecerea granitei"/></td>
              </tr>
            </table>
            <NumberInput validate={[number()]} source="crossBorderKmLimit" label="Limita max in afara tarii" helperText="Nelimitat in cazul valorii 0." />
            <NumberInput validate={[number()]} source="limitedKm" label="Limita kilometri" helperText="Nelimitat in cazul valorii 0." />
            <NumberInput validate={[number()]} source="dislocationCost" label="Cost dislocare" helperText="Gratuit in cazul valorii 0."/>
            <NumberInput validate={[number()]} source="roadTollPrice" label="Taxa drum" helperText="Gratuit in cazul valorii 0." />
            <NumberInput validate={[number()]} source="minLicensePeriod" label="Experienta minima(permis)" helperText="Nu se aplica in cazul valorii 0."/>
            <NumberInput validate={[number()]} source="minimumAge" label="Varsta minima" helperText="Nu se aplica in cazul valorii 0."/>
            <BooleanInput source="fullToFull" label="Plin la plin"  />
            <BooleanInput source="meetAndGreet" />
            <BooleanInput source="freeAdditionalDriver" label="Sofer aditional gratuit" />
            <BooleanInput source="fullPaymentAtPickup" label="Achitare la preluare" />
            <BooleanInput source="freeChildChair" label="Scaun copil gratuit"  />
            <BooleanInput source="freeCancelling" label="Anulare gratuita inainte cu 48 de ore" />
            <BooleanInput source="freeModification" label="Modificare gratuita a rezervarii" />
            <BooleanInput source="canReduceSecondInsuranceDeposit" label="Posibilitate de reducere a depozitului"/>
            <BooleanInput source="canExcludeThirdInsuranceDeposit" label="Posibilitate de excludere a depozitului"/>
            <BooleanInput source="cash" label="Accepta plata cash"/>
            <BooleanInput source="cashDeposit" label="Accepta depozit cash"/>
            <BooleanInput source="debitCardDeposit" label="Accepta depozit cu card debit"/>
            <BooleanInput source="creditCardDeposit" label="Accepta depozit cu card credit"/>
            <FileInput source="locationTax" label="Taxe locatii" multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                <FileField source="src" title="filename" />
            </FileInput>
          </Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <h3>Optiuni</h3>
            <table>
              <tr>
                <td><NumberInput validate={[number()]} source="firstChildChairPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasFirstChildChair" label="Scaun copil 0-0.5 ani" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="secondChildChairPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasSecondChildChair" label="Scaun copil 0.5-3 ani" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="thirdChildChairPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasThirdChildChair" label="Scaun copil peste 3 ani" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="gpsPrice" label="Pret pe zi"/></td>
                <td/>
                <td><BooleanInput source="hasGps" label="GPS" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="snowChainsPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasSnowChains" label="Lanturi de zapada"/></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="additionalDriverPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasAdditionalDriver" label="Sofer aditional" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="wifiRouterPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasWifiRouter" label="Wi-Fi Router" /></td>
              </tr>
              <tr>
                <td><NumberInput validate={[number()]} source="skiSupportPrice" label="Pret pe zi" /></td>
                <td/>
                <td><BooleanInput source="hasSkiSupport" label="Suport ski" /></td>
              </tr>
            </table>
          </Grid>
        </Container>
      </Box>

      <Box borderRadius="borderRadius" {...boxProps} fullWidth={true}>
        <Container>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <h3>Detalii preluare si predare</h3>
            <SelectInput label="Mod taxa" source="taxMode" choices={[
                { id: '0', name: 'Gratuit' },
                { id: '1', name: 'Tot timpul aplicabila' },
                { id: '2', name: 'Taxa in afara orarului' },
                { id: '3', name: 'Taxa in afara orarului si pe tot parcursul weekend-ului' }
              ]} />
            <TextInput source="openingTime" placeholder="9:00" label="Deschidere" />
            <TextInput source="closingTime" placeholder="17:00" label="Inchidere"/>
            <NumberInput validate={[number()]} source="pickupCost" label="Taxa preluare" />
            <NumberInput validate={[number()]} source="dropoffCost" label="Taxa predare" />
          </Grid>
        </Container>
      </Box>

      <ImageInput source="logoProvider" multiple={false} label="Logo" accept="image/*">
          <ImageField source="src" title="title" />
      </ImageInput>
      <RichTextInput fullWidth={true} source="policy" label="Politica" />
      <RichTextInput fullWidth={true} source="policyEn" label="Politica(engleza)" />
    </SimpleForm>
  </Create>
);